import { getWhatAppRedirectionUrlHelp } from './getWhatsAppRedirectionUrl';

/**
 * common function to open whatsapp for messaging the store owner
 * display/registered number
 * @param {Object} - userData, phoneNumber, storeUrl
 */
export const whatsappMessageRender = () => {
  window.location.href = getWhatAppRedirectionUrlHelp();
};
