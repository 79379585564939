/**
 * This is a reusable component for whatsapp functionality
 * desktop views of multiple headers
 */

import { MenuItem, WaNoDp } from './index.styles';
import { WhatsAppIcon } from '@/assets/svgExports/WhatsAppIcon';
import { useState } from 'react';
import { whatsappMessageRender } from '@/utils/whatsappMessageRender';
import useStoreContactDetails from 'src/hooks/useStoreContactDetails';

const WhatsAppIconComp = () => {
  const [showWANo, setShowWANo] = useState(false);

  const { storePhone } = useStoreContactDetails();

  const onContactBtnClick = () => {
    whatsappMessageRender();
  };

  return (
    !!storePhone && (
      <MenuItem>
        <WhatsAppIcon onClick={() => setShowWANo(!showWANo)} size="24" />
        {!!storePhone && showWANo && (
          <WaNoDp onClick={onContactBtnClick}>{storePhone}</WaNoDp>
        )}
      </MenuItem>
    )
  );
};

export default WhatsAppIconComp;
